import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { Permission } from '@/types/permission'

export function updatePermission(
  uuid: string,
  body: Partial<Permission>
): Promise<AxiosResponse<Permission>> {
  const payload = body
  delete payload.roles
  return instance.put(`/iam/permission/${uuid}`, body)
}
