import { computed, ComputedRef, Ref, ref } from 'vue'
import { Project } from '@/types/project'
import { getProjects } from '@/api/project/getProjects'
import { deleteProject } from '@/api/project/deleteProject'
import { createProject } from '@/api/project/createProject'
import { useLoading, Loading } from '@/composable/useLoading'

interface UseProject {
  data: Ref<Project[]>
  fetchAll: () => Promise<Project[]>
  remove: () => Promise<null>
  add: (body: Project) => Promise<null>
  loading: ComputedRef<Loading>
}

export function useProject(initFetchAll?: boolean): UseProject {
  const data = ref<Project[]>([])

  const { state } = useLoading()

  async function fetchAll() {
    state.getAll = true
    return new Promise<Project[]>((resolve, reject) => {
      getProjects()
        .then(async ({ data: projects }) => {
          data.value = projects

          resolve(projects)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function remove() {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deleteProject()
        .then((res) => {
          console.log(res)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  async function add(body: Project) {
    state.create = true
    return new Promise<null>((resolve, reject) => {
      createProject(body)
        .then((res) => {
          console.log(res)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  if (initFetchAll) {
    fetchAll()
  }

  return {
    data,
    fetchAll,
    remove,
    add,
    loading: computed(() => state),
  }
}
