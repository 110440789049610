<template>
  <MasterTable
    title="Behörigheter"
    :rows="rows"
    :columns="columns"
    :loading="loading"
    :add="add"
    :update="update"
    :remove="remove"
    :reset-form-model="resetFormModel"
    :form-model="model"
    remove-item-text-prop="description"
    @update-form-model="(row) => (model = row)"
  >
    <template v-slot:form>
      <div class="row">
        <div class="col-12 q-pa-md">
          <div class="row">
            <div class="col-12">
              <q-input
                dense
                v-model="model.name"
                label="Behörighet*"
                :rules="[requiredRule]"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <q-input
                dense
                v-model="model.description"
                :rules="[requiredRule]"
                label="Beskrivning*"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <q-select
                dense
                use-input
                use-chips
                input-debounce="0"
                v-model="model.group"
                :options="groups"
                label="Grupp*"
                @filter="filterFn"
                :rules="[requiredRule]"
                @new-value="onAddGroup"
              />
            </div>
          </div>
          <div class="row q-mt-md">
            <div class="text-weight-bold">Projekt</div>
            <div class="col-12" v-for="project in projects" :key="project.name">
              <q-checkbox
                :value="true"
                v-model="model[project.name]"
                :label="project.description"
              />
            </div>
          </div>
          <q-field
            style="display: none"
            :model-value="isProjectSelected"
            :rules="[booleanTrueRequired]"
          >
          </q-field>

          <q-slide-transition>
            <div class="text-negative" v-if="!isProjectSelected">
              <div class="q-pa-sm">
                <q-icon name="mdi-alert-circle" />
                Minst ett projekt måste väljas
              </div>
            </div>
          </q-slide-transition>
        </div>
      </div>
    </template>
  </MasterTable>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import MasterTable from '@/components/master/MasterTable.vue'
import { useProject } from '@/composable/useProject'
import { usePermission } from '@/composable/usePermission'
import {
  required as requiredRule,
  booleanTrueRequired,
} from '@/common/formValidationRules'
import { v4 as uuidv4 } from 'uuid'
import { Permission } from '@/types/permission'
import capitalize from 'lodash.capitalize'
import { Role } from '@/types/role'

export default defineComponent({
  name: 'Permissions',

  components: {
    MasterTable,
  },

  setup() {
    const { loading: loadingStateProjects, data: projects } = useProject(true)
    const filterText = ref('')
    const {
      loading: loadingStatePermissions,
      data: permissions,
      add,
      update,
      remove,
    } = usePermission(true)

    function createFormModel(): Permission {
      return {
        name: '',
        description: '',
        uuid: uuidv4(),
        group: null,
        krosatag: false,
        gotalandstag: false,
        oresund: false,
        master: false,
        test: false,
        hidden: false,
      }
    }
    const model = ref<Permission>(createFormModel())

    function resetFormModel() {
      model.value = createFormModel()
    }

    const groupsFromPermissions = computed(() => {
      return [...new Set(permissions.value.map((x) => x.group))]
    })

    const ownGroups = ref<string[]>([])

    const groups = computed(() => {
      return [...ownGroups.value, ...groupsFromPermissions.value].filter(
        (x) =>
          !filterText.value ||
          (x || '').toLowerCase().includes(filterText.value)
      )
    })

    const formatProjectCheck = (x: boolean) => (x ? 'x' : '')
    const styleProjectCheck = 'font-weight: bold'

    const columns = computed(() => {
      return [
        {
          name: 'group',
          label: 'Grupp',
          align: 'left',
          field: 'group',
          sortable: true,
        },
        {
          name: 'description',
          label: 'Beskrivning',
          align: 'left',
          field: 'description',
          sortable: true,
        },
        {
          name: 'name',
          label: 'Behörighet',
          align: 'left',
          field: 'name',
          sortable: true,
        },
        {
          name: 'roles',
          label: 'Roller',
          format: (v: Role[]) => v.length,
          align: 'left',
          field: 'roles',
          sortable: true,
        },
        {
          name: 'krosatag',
          label: 'Krösatåg',
          align: 'left',
          field: 'krosatag',
          sortable: true,
          style: styleProjectCheck,
          format: formatProjectCheck,
        },
        {
          name: 'gotalandstag',
          label: 'Götalandståg',
          align: 'left',
          field: 'gotalandstag',
          sortable: true,
          style: styleProjectCheck,
          format: formatProjectCheck,
        },
        {
          name: 'oresund',
          label: 'Öresund',
          align: 'left',
          field: 'oresund',
          sortable: true,
          style: styleProjectCheck,
          format: formatProjectCheck,
        },
        {
          name: 'master',
          label: 'Admin',
          align: 'left',
          field: 'master',
          sortable: true,
          style: styleProjectCheck,
          format: formatProjectCheck,
        },
        {
          name: 'test',
          label: 'Test',
          align: 'left',
          field: 'test',
          sortable: true,
          style: styleProjectCheck,
          format: formatProjectCheck,
        },
      ]
    })

    const loading = computed(() => {
      return {
        ...loadingStatePermissions.value,
        getAll:
          loadingStateProjects.value.getAll ||
          loadingStatePermissions.value.getAll,
      }
    })

    const rows = computed(() => {
      return permissions.value
    })

    function onAddGroup(
      val: string,
      done: (val: string, type: string) => void
    ) {
      const v = capitalize(val)
      if (!groups.value.includes(v)) {
        ownGroups.value.push(v)
      }
      done(capitalize(val), 'add-unique')
    }

    const isProjectSelected = computed(() => {
      return (
        model.value.gotalandstag ||
        model.value.oresund ||
        model.value.krosatag ||
        model.value.master ||
        model.value.test
      )
    })

    return {
      permissions,
      projects,
      columns,
      model,
      requiredRule,
      rows,
      add,
      update,
      remove,
      loadingStateProjects,
      loading,
      resetFormModel,
      booleanTrueRequired,
      groups,
      onAddGroup,
      isProjectSelected,
      filterFn(val: string, update: (cb: () => void) => void) {
        update(() => {
          const needle = val.toLowerCase()
          filterText.value = needle
        })
      },
    }
  },
})
</script>
