import { Ref, ref, ComputedRef, computed } from 'vue'
import { Permission } from '@/types/permission'
import { getPermissions } from '@/api/permission/getPermissions'
import { useLoading, Loading } from '@/composable/useLoading'
import { updatePermission } from '@/api/permission/updatePermission'
import { createPermission } from '@/api/permission/createPermission'
import { deletePermission } from '@/api/permission/deletePermission'

interface UsePermission {
  data: Ref<Permission[]>
  fetchAll: () => Promise<Permission[]>
  add: (body: Partial<Permission>) => Promise<Permission>
  update: (body: Partial<Permission>) => Promise<Permission>
  remove: (uuid: string) => Promise<null>
  loading: ComputedRef<Loading>
}

export function usePermission(initFetchAll?: boolean): UsePermission {
  const data = ref<Permission[]>([])
  const { state } = useLoading()

  async function fetchAll() {
    state.getAll = true
    return new Promise<Permission[]>((resolve, reject) => {
      getPermissions()
        .then(({ data: permissions }) => {
          data.value = permissions
          resolve(permissions)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function add(body: Partial<Permission>) {
    state.create = true
    return new Promise<Permission>((resolve, reject) => {
      createPermission(body)
        .then(({ data: permission }) => {
          data.value.push(permission)
          resolve(permission)
        })
        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  async function update(body: Partial<Permission>) {
    state.update = true
    const uuid = body.uuid as string
    body.hidden = false
    if (body.uuid) {
      delete body.uuid
    }
    return new Promise<Permission>((resolve, reject) => {
      updatePermission(uuid, body)
        .then(({ data: permission }) => {
          data.value = data.value.map((item) => {
            if (item.uuid === permission.uuid) {
              return {
                ...item,
                ...permission,
              }
            }

            return item
          })
          resolve(permission)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  async function remove(uuid: string) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deletePermission(uuid)
        .then(() => {
          data.value = data.value.filter((item) => item.uuid !== uuid)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  if (initFetchAll) {
    fetchAll()
  }

  return {
    data,
    fetchAll,
    add,
    update,
    remove,
    loading: computed(() => state),
  }
}
